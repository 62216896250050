import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Outlet } from 'react-router-dom';

import ThemeContextProvider from '@/theme/ThemeProvider';

import SuperAppBar from './components/SuperAppBar';
import Sidebar from './components/Sidebar';

const drawerWidth = 220;

export const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar
}));

const OutletContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'open' && prop !== 'isScreenSizeMediumAndAbove'
})<{
  open: boolean;
  isScreenSizeMediumAndAbove: boolean;
}>(({ theme, open, isScreenSizeMediumAndAbove }) => ({
  flexGrow: 1,
  p: 4,
  paddingTop: 10,
  width: (function () {
    if (!isScreenSizeMediumAndAbove) {
      return '100%';
    }
    return open ? `calc(100% - ${drawerWidth}px)` : `calc(100% - 65px)`;
  })(),
  marginTop: 14
}));

const DashboardLayout = () => {
  const theme = useTheme();
  const isScreenSizeMediumAndAbove = useMediaQuery(theme.breakpoints.up('md'));
  const [open, setOpen] = React.useState(isScreenSizeMediumAndAbove ? true : false);

  const handleDrawerToggle = () => {
    setOpen((prev) => !prev);
  };

  return (
    <ThemeContextProvider>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <SuperAppBar
          open={open}
          drawerWidth={drawerWidth}
          handleDrawerToggle={handleDrawerToggle}
        />
        <Sidebar
          isScreenSizeMediumAndAbove={isScreenSizeMediumAndAbove}
          drawerWidth={drawerWidth}
          open={open}
          handleDrawerToggle={handleDrawerToggle}
        />
        <OutletContainer
          isScreenSizeMediumAndAbove={isScreenSizeMediumAndAbove}
          open={open}
          component="main"
        >
          <DrawerHeader />
          <Box px="20px">
            <Outlet />
          </Box>
        </OutletContainer>
      </Box>
    </ThemeContextProvider>
  );
};

export default DashboardLayout;
